<template>
    <div>
        <div class="listing__item" data-aos="fade-up">
            <div class="listing__item--date">
                <base-idea-date
                    v-if="date"
                    :created="idea.created"
                    :idea-status="$t(ideasStatusList[idea.state].text)"
                    :status-class="idea.state"
                ></base-idea-date>
            </div>
            <div class="listing__item--body">
                <div class="d-flex justify-content-between">
                    <div class="listing__item--contents">
                        <p class="listing__item--title">
                            <router-link :to="to">
                                {{ idea.title }}
                            </router-link>
                            <span
                                v-if="showIdeaState"
                                class="status"
                                :class="idea.state"
                            >
                                - {{ $t(idea.state) }}
                            </span>
                        </p>
                        <!-- <p
                            class="listing__item--shortdes"
                            v-if="
                                description &&
                                    idea.description !== null &&
                                    idea.description != '' &&
                                    !showIdeators
                            "
                        >
                            {{ idea.description }}
                        </p> -->
                        <p class="listing__item--opco" v-if="showOpco">
                            {{ idea.country }}
                        </p>
                        <div
                            class="listing__item--ideators"
                            v-if="showIdeators"
                        >
                            <p class="ideator-name">
                                {{ getIdeatorsList(idea) }}
                            </p>
                        </div>
                    </div>
                    <div v-if="previewImage" class="listing__item--image">
                        <router-link :to="to">
                            <img
                                loading="lazy"
                                :src="previewImage"
                                alt=""
                                class="image-top"
                                :class="{ 'not-found': !previewImage }"
                            />
                            <img
                                loading="lazy"
                                :src="previewImage"
                                alt=""
                                :class="{ 'not-found': !previewImage }"
                            />
                        </router-link>
                    </div>
                </div>
                <div v-if="showCategory" class="listing__item--category-edit">
                    <p class="listing__item--category">
                        {{
                            idea && idea.category && idea.category.title
                                ? idea.category.title
                                : ''
                        }}
                    </p>
                    <div
                        v-if="$route.name === 'user-ideas'"
                        class="listing__item--edit"
                    >
                        <router-link
                            v-if="idea.state === 'resubmit'"
                            class="btn btn-primary"
                            :to="{
                                name: 'resubmit-idea',
                                params: {
                                    uuid: idea.uuid,
                                },
                            }"
                        >
                            {{ $t('Resubmit') }}
                        </router-link>
                        <router-link
                            v-else-if="
                                [
                                    'submitted',
                                    'approved',
                                    'planned',
                                    'implemented',
                                ].includes(idea.state)
                            "
                            class="btn btn-primary"
                            :to="{
                                name: 'edit-idea',
                                params: {
                                    uuid: this.idea.uuid,
                                },
                            }"
                        >
                            {{ $t('Edit') }}
                        </router-link>
                    </div>
                </div>
                <p
                    class="listing__item--shortdes"
                    v-if="
                        description &&
                            idea.description !== null &&
                            idea.description != ''
                    "
                >
                    {{ idea.description }}
                </p>
                <!-- Likes -->
                <div class="listing__item--reviews" v-if="showReviews">
                    <base-reviews
                        v-if="idea.id"
                        :object="idea"
                        @social="social"
                        :to="to"
                    ></base-reviews>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ideasReviews } from '@/modules/ideas/mixins/ideasReviews.js'
import { mapGetters } from 'vuex'

export default {
    mixins: [ideasReviews],
    components: {},
    props: {
        idea: Object,
        description: Boolean,
        date: Boolean,
        showIdeaState: {
            type: Boolean,
            default: true,
        },
        to: {
            type: Object,
            default: function() {
                return {}
            },
        },
        showReviews: {
            type: Boolean,
            default: true,
        },
        showIdeators: {
            type: Boolean,
            default: true,
        },
        showOpco: {
            type: Boolean,
            default: true,
        },
        showCategory: {
            type: Boolean,
            default: true,
        },
    },

    methods: {
        getIdeatorsList(idea) {
            const ideators = [idea?.owner_details?.display_name || ''].concat(
                idea.members.map(member => {
                    return member?.user_detail?.display_name || ''
                }),
            )
            const filteredIdeators = ideators.filter(ideator => ideator)
            return filteredIdeators.join(', ')
        },
    },
    computed: {
        ...mapGetters(['ideasStatusList']),
        previewImage() {
            return this.idea.preview_image
                ? this.idea.preview_image
                : this.idea.medias.length
                ? this.idea.medias[0].file
                : ''
        },
    },
    watch: {},
}
</script>

<style lang="scss" scoped>
img {
    width: 250px;
    height: 250px;
    object-fit: cover;
}
.listing__item--title {
    padding-right: rem(50px);
    @media screen and (max-width: 767px) {
        padding-right: 0;
        padding-top: 20px;
    }
    background: none !important;
    -webkit-background-clip: initial !important;
    -webkit-text-fill-color: initial !important;
    &:hover {
        text-decoration: none;
    }
    a {
        background: linear-gradient(
            160deg,
            var(--primary) 0%,
            var(--green) 100%
        ) !important;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
    }
    .status {
        color: var(--secondary);
        font-size: rem(20px);
        font-weight: 600;
        text-transform: capitalize;
        &.approved {
            color: var(--green);
        }
        &.implemented {
            color: var(--primary);
        }
    }
}
.listing__item--edit {
    @media screen and (min-width: 768px) {
        padding-left: 20px;
    }
    .btn-primary {
        min-width: 8rem;
    }
    .btn {
        padding: rem(20px) rem(20px);
        line-height: 0.5;
        text-transform: none !important;
        font-size: rem(20px);
        font-weight: 500;
    }
}
.listing__item--category-edit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: normal;
    }
}
.listing__item--shortdes {
    word-break: break-word;
}
.listing__item--opco {
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--primary);
}
.listing__item--ideators {
    display: flex;
    flex-wrap: wrap;
    .ideator-name {
        font-weight: bold;
        font-size: 1.5rem;
        color: var(--primary);
    }
}
.not-found {
    max-width: 250px !important;
    height: 250px !important;
    object-fit: unset !important;
}

.ar {
    .listing__item--title {
        padding-left: rem(50px);
        padding-right: 0;
        @media screen and (max-width: 767px) {
            padding-right: 0;
            padding-left: 0;
        }
        &:after {
            @media screen and (max-width: 767px) {
                right: 4%;
            }
        }
    }
    .listing__item--edit {
        @media screen and (min-width: 768px) {
            padding-left: 0px;
            padding-right: 20px;
        }
    }
}
</style>
