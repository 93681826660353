var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"listing__item",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"listing__item--date"},[(_vm.date)?_c('base-idea-date',{attrs:{"created":_vm.idea.created,"idea-status":_vm.$t(_vm.ideasStatusList[_vm.idea.state].text),"status-class":_vm.idea.state}}):_vm._e()],1),_c('div',{staticClass:"listing__item--body"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"listing__item--contents"},[_c('p',{staticClass:"listing__item--title"},[_c('router-link',{attrs:{"to":_vm.to}},[_vm._v(" "+_vm._s(_vm.idea.title)+" ")]),(_vm.showIdeaState)?_c('span',{staticClass:"status",class:_vm.idea.state},[_vm._v(" - "+_vm._s(_vm.$t(_vm.idea.state))+" ")]):_vm._e()],1),(_vm.showOpco)?_c('p',{staticClass:"listing__item--opco"},[_vm._v(" "+_vm._s(_vm.idea.country)+" ")]):_vm._e(),(_vm.showIdeators)?_c('div',{staticClass:"listing__item--ideators"},[_c('p',{staticClass:"ideator-name"},[_vm._v(" "+_vm._s(_vm.getIdeatorsList(_vm.idea))+" ")])]):_vm._e()]),(_vm.previewImage)?_c('div',{staticClass:"listing__item--image"},[_c('router-link',{attrs:{"to":_vm.to}},[_c('img',{staticClass:"image-top",class:{ 'not-found': !_vm.previewImage },attrs:{"loading":"lazy","src":_vm.previewImage,"alt":""}}),_c('img',{class:{ 'not-found': !_vm.previewImage },attrs:{"loading":"lazy","src":_vm.previewImage,"alt":""}})])],1):_vm._e()]),(_vm.showCategory)?_c('div',{staticClass:"listing__item--category-edit"},[_c('p',{staticClass:"listing__item--category"},[_vm._v(" "+_vm._s(_vm.idea && _vm.idea.category && _vm.idea.category.title ? _vm.idea.category.title : '')+" ")]),(_vm.$route.name === 'user-ideas')?_c('div',{staticClass:"listing__item--edit"},[(_vm.idea.state === 'resubmit')?_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                            name: 'resubmit-idea',
                            params: {
                                uuid: _vm.idea.uuid,
                            },
                        }}},[_vm._v(" "+_vm._s(_vm.$t('Resubmit'))+" ")]):(
                            [
                                'submitted',
                                'approved',
                                'planned',
                                'implemented' ].includes(_vm.idea.state)
                        )?_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                            name: 'edit-idea',
                            params: {
                                uuid: this.idea.uuid,
                            },
                        }}},[_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")]):_vm._e()],1):_vm._e()]):_vm._e(),(
                    _vm.description &&
                        _vm.idea.description !== null &&
                        _vm.idea.description != ''
                )?_c('p',{staticClass:"listing__item--shortdes"},[_vm._v(" "+_vm._s(_vm.idea.description)+" ")]):_vm._e(),(_vm.showReviews)?_c('div',{staticClass:"listing__item--reviews"},[(_vm.idea.id)?_c('base-reviews',{attrs:{"object":_vm.idea,"to":_vm.to},on:{"social":_vm.social}}):_vm._e()],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }